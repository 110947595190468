import FontFaceObserver from 'fontfaceobserver';

if( true ){

  let htmlDoc = document.documentElement;

  let whitneyA =    new FontFaceObserver( "Whitney A" );
  let whitneyB =    new FontFaceObserver( "Whitney B" );
  let whitneySCA =  new FontFaceObserver( "Whitney SC A" );
  let whitneySCB =  new FontFaceObserver( "Whitney SC B" );
  let archerA =     new FontFaceObserver( "Archer A" );
  let archerB =     new FontFaceObserver( "Archer B" );

  document.documentElement.classList.add('fonts-loading');
  console.log('fonts-loading');

  Promise.all([
    whitneyA.load(),
    whitneyB.load(),
    whitneySCA.load(),
    whitneySCB.load(),
    archerA.load(),
    archerB.load()
  ]).then(function(){
    htmlDoc.classList.remove('fonts-loading');
    htmlDoc.classList.add('fonts-loaded');
    sessionStorage.fontsLoaded = true;
    console.log('fonts-loaded');
  }).catch(function () {
    htmlDoc.classList.remove('fonts-loading');
    html.classList.add('fonts-failed');
    sessionStorage.fontsLoaded = false;
    console.log('fonts-failed');
  });

}
